// src/Modules/PieceRateTracker/CrewView/CrewViewLevel.js
import React from 'react';
import CrewViewItemDetails from './CrewViewItemDetails';

const CrewViewLevel = ({ level, crewName }) => {
  return (
    <>
      <tr className="level-row">
        <td colSpan="5">
          <div className="level-name">{level.levelName}</div>
        </td>
      </tr>
      <tr className="level-header-row">
        <th>Item</th>
        <th>Price</th>
        <th>Total Pay</th>
        <th>Completed</th>
        <th>Completion Date</th>
      </tr>
      {level.items.map((item) => (
        <CrewViewItemDetails key={item.id} item={item} crewName={crewName} />
      ))}
    </>
  );
};

export default CrewViewLevel;