// src/Modules/PieceRateTracker/CrewView/CrewViewTable.js
import React from 'react';
import { useSelector } from 'react-redux';
import CrewViewLevel from './CrewViewLevel';
import './../PieceRateBody/Table.css';
import './../PieceRateBody/Level.css';
import './../PieceRateBody/ItemDetails.css';
import './../PieceRateBody/ItemResponsibilities.css';

const CrewViewTable = ({ crewName }) => {
  const items = useSelector((state) => state.crewView.items);

  return (
    <div className="level-table-container">
      <table className="level-table">
        <tbody>
          {items.map((job) => (
            <React.Fragment key={job.jobName}>
              <tr className="level-row">
                <td colSpan="7">
                  <div className="level-name">{job.jobName}</div>
                </td>
              </tr>
              {job.levels.map((level) => (
                <CrewViewLevel key={level.levelName} level={level} crewName={crewName} />
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default CrewViewTable;