import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateItemCompletion } from './crewViewActions';

const CrewViewItemDetails = ({ item, crewName }) => {
  const dispatch = useDispatch();
  const updatingItemId = useSelector((state) => state.crewView.updatingItemId);

  const [editableCompletion, setEditableCompletion] = useState(item.completion || 0);
  const [isSaveVisible, setIsSaveVisible] = useState(false);

  useEffect(() => {
    setEditableCompletion(item.completion || 0);
    setIsSaveVisible(false);
  }, [item.completion]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const calculateItemTotal = () => {
    const price = item.defaultPrice || 0;
    const pricingType = item.pricingType;
    const quantity = item.quantity || 0;
    const sqFoot = item.sqFootage || 0;

    if (pricingType === 'perSqFoot') {
      return formatCurrency(price * sqFoot);
    } else if (pricingType === 'perItem') {
      return formatCurrency(price * quantity);
    }
    return formatCurrency(0);
  };

  const handleCompletionChange = (event) => {
    const completion = parseInt(event.target.value);
    setEditableCompletion(completion);
    setIsSaveVisible(true);
  };

  const handleSaveCompletion = () => {
    dispatch(updateItemCompletion(item.id, editableCompletion, crewName));
  };

  const completionOptions = item.pricingType === 'perSqFoot'
    ? [
        { value: 0, label: '0%' },
        { value: 25, label: '25%' },
        { value: 50, label: '50%' },
        { value: 75, label: '75%' },
        { value: 100, label: '100%' },
      ]
    : Array.from({ length: (item.quantity || 0) + 1 }, (_, index) => ({
        value: index,
        label: index.toString(),
      }));

  return (
    <tr className="item-row">
      <td>{item.name}</td>
      <td>
        {item.pricingType === 'perSqFoot' ? (
          <span>{formatCurrency(item.defaultPrice)} / Sq Ft</span>
        ) : (
          <>
            {formatCurrency(item.defaultPrice)}
            {item.pricingType === 'perItem' && (
              <span> ({item.quantity || ''} items)</span>
            )}
          </>
        )}
      </td>
      <td>{calculateItemTotal()}</td>
      <td>
        {item.pricingType === 'perItem' && item.quantity > 50 ? (
          <input
            type="number"
            value={editableCompletion}
            onChange={handleCompletionChange}
            min="0"
            max={item.quantity}
            className="item-completion-input"
            placeholder="Completion"
            disabled={updatingItemId === item.id}
          />
        ) : (
          <select
            value={editableCompletion}
            onChange={handleCompletionChange}
            disabled={updatingItemId === item.id}
          >
            {completionOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
        {updatingItemId === item.id && <span>Updating...</span>}
      </td>
      <td>{item.date || ''}</td>
      {isSaveVisible && (
        <td>
          <button onClick={handleSaveCompletion} className="save-completion-button">
            Save
          </button>
        </td>
      )}
    </tr>
  );
};

export default CrewViewItemDetails;
