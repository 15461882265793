// JobDropdown.js
import React from 'react';

const JobDropdown = ({ jobs, selectedJob, onJobChange, onNewJob }) => {
  return (
    <div className="job-selector">
      <select value={selectedJob} onChange={onJobChange}>
        {jobs.length === 0 ? (
          <option value="">No jobs available</option>
        ) : (
          <>
            <option value="">Select a job</option>
            {jobs.map((job) => (
              <option key={job} value={job}>
                {job.replace(/_/g, ' ')}
              </option>
            ))}
          </>
        )}
      </select>
      <button onClick={onNewJob}>New Job</button>
    </div>
  );
};

export default JobDropdown;