import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import API_BASE_URL from '../../../constants/apiConfig';
import { addPerson, updatePerson, setAllPeople } from '../pieceRateActions';

const usePeopleActions = () => {
  const dispatch = useDispatch();

  const fetchPeople = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/people`);
      if (response.ok) {
        const data = await response.json();
        dispatch(setAllPeople(data.people)); // Ensure this dispatches setAllPeople correctly
      } else {
        console.error('Failed to fetch people');
      }
    } catch (error) {
      console.error('Error fetching people:', error);
    }
  }, [dispatch]);

  const addPersonAsync = useCallback(async (person) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/people`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(person),
      });

      if (response.ok) {
        const data = await response.json();
        dispatch(addPerson({ ...person, id: data.id }));
      } else {
        console.error('Failed to add person');
      }
    } catch (error) {
      console.error('Error adding person:', error);
    }
  }, [dispatch]);

  const removePersonAsync = useCallback(async (personId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/people/${personId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        dispatch({ type: 'REMOVE_PERSON', payload: personId });
      } else {
        console.error('Failed to remove person');
      }
    } catch (error) {
      console.error('Error removing person:', error);
    }
  }, [dispatch]);

  const updatePersonAsync = useCallback(async (person) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/people/${person.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(person),
      });

      if (response.ok) {
        dispatch(updatePerson(person));
      } else {
        console.error('Failed to update person');
      }
    } catch (error) {
      console.error('Error updating person:', error);
    }
  }, [dispatch]);

  return {
    fetchPeople,
    addPersonAsync,
    removePersonAsync,
    updatePersonAsync,
  };
};

export default usePeopleActions;
