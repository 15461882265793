import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchItemsForCrew, fetchFinancialSummary, updateFinancialSummary } from './crewViewActions';
import CrewViewTable from './CrewViewTable';
import CrewFinancialSummary from './CrewFinancialSummary';
import './CrewDashboard.css';

const CrewDashboard = () => {
  const { crewName } = useParams();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.crewView.items);
  const beginningBalance = useSelector((state) => state.crewView.beginningBalance);
  const earnings = useSelector((state) => state.crewView.earnings);
  const [isLoading, setIsLoading] = useState(true);
  const [payrollAmount, setPayrollAmount] = useState(0);
  const [additionalEarnings, setAdditionalEarnings] = useState('');
  const [currentBalance, setCurrentBalance] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (crewName) {
        await dispatch(fetchItemsForCrew(crewName));
        const financialSummary = await dispatch(fetchFinancialSummary(crewName));
        setPayrollAmount(financialSummary?.payrollAmount || 0);
        setAdditionalEarnings(financialSummary?.additionalEarnings || '');
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, crewName]);

  useEffect(() => {
    const updatedBalance = beginningBalance + earnings + (parseFloat(additionalEarnings) || 0) - (parseFloat(payrollAmount) || 0);
    setCurrentBalance(updatedBalance);
  }, [beginningBalance, earnings, additionalEarnings, payrollAmount]);

  const handleSave = async (payroll, additionalEarnings) => {
    if (crewName) {
      await updateFinancialSummary(crewName, payroll, additionalEarnings);
      setPayrollAmount(payroll);
      setAdditionalEarnings(additionalEarnings);
    }
  };

  const handleAdditionalEarningsChange = (earnings) => {
    setAdditionalEarnings(earnings);
  };

  const handlePayrollChange = (payroll) => {
    setPayrollAmount(payroll);
  };

  if (isLoading) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="crew-dashboard">
      <h2 className="crew-dashboard-header">Crew Dashboard - {crewName}</h2>
      {crewName && (
        <>
          <CrewFinancialSummary
            crewName={crewName}
            startingBalance={beginningBalance}
            endingBalance={currentBalance}
            earningsThisPeriod={earnings}
            payrollAmount={payrollAmount}
            additionalEarnings={additionalEarnings}
            onAdditionalEarningsChange={handleAdditionalEarningsChange}
            onPayrollChange={handlePayrollChange}
            onSave={handleSave}
          />
          {items && items.length > 0 ? (
            <CrewViewTable crewName={crewName} />
          ) : (
            <p className="no-items-message">No items found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default CrewDashboard;
