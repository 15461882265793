import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../../../constants/apiConfig';
import { defaultItems, generateItemData, generateCrewAssignments, generateResponsibilities } from '../pieceRateSchema';

const useJobData = (setIsEditable) => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [jobName, setJobName] = useState('');
  const [isNewJob, setIsNewJob] = useState(false);
  const [loading, setLoading] = useState(true);
  const crewsAssignedToJob = useSelector((state) => state.pieceRate.crewsAssignedToJob);

  const fetchJobs = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/piece-rates/jobs`);
      if (response.ok) {
        const data = await response.json();
        setJobs(data.jobs || []);
      } else {
        console.error('Failed to fetch jobs');
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleJobChange = (event) => {
    const selectedJob = event.target.value;
    setSelectedJob(selectedJob);
    setJobName(selectedJob.replace(/_/g, ' '));
    setIsEditable(false);
    setIsNewJob(false);
  };

  const handleJobNameChange = (event) => {
    setJobName(event.target.value);
  };

  const createNewJob = async (newJobName) => {
    const jobName = newJobName.trim().replace(/\s+/g, '_');
  
    const jobExists = jobs.some((job) => job === jobName);
  
    if (jobExists) {
      alert('A job with the same name already exists. Please choose a different name.');
      return;
    }
  
    const levelItems = defaultItems.map((item, index) => ({
      id: `${item.id}-1-${index + 1}`,
      name: item.name,
      pricingType: item.pricingType,
    }));
    const defaultLevelData = {
      'Level 1': {
        sqFootage: 0,
        items: levelItems.map(item => item.id),
      },
    };
    const itemData = generateItemData(levelItems);
    const crewAssignments = generateCrewAssignments(levelItems);
    const responsibilities = generateResponsibilities(levelItems);
  
    const jobData = {
      job: jobName,
      current_big_dict: JSON.stringify({
        itemData: itemData,
        crewItemAssignments: crewAssignments,
        responsibilities: responsibilities,
        levelData: defaultLevelData,
        projectManagerAssignedToJob: null,
      }),
    };
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/piece-rates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jobData),
      });
  
      if (response.ok) {
        fetchJobs();
        setSelectedJob(jobName);
        setJobName(newJobName);
        setIsEditable(true);
        setIsNewJob(true);
      } else {
        console.error('Failed to create new job');
      }
    } catch (error) {
      console.error('Error creating new job:', error);
    }
  };

  const handleSave = async (itemData, crewItemAssignments, responsibilities, levelData, peopleOptions, projectManagerAssignedToJob) => {
    try {
      const jobData = {
        job: jobName.replace(/\s+/g, '_'),
        current_big_dict: JSON.stringify({
          itemData,
          crewItemAssignments,
          responsibilities,
          levelData,
          crewsAssignedToJob,
          projectManagerAssignedToJob,
        }),
      };
  
      let response;
      if (isNewJob) {
        response = await fetch(`${API_BASE_URL}/api/piece-rates`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jobData),
        });
      } else {
        response = await fetch(`${API_BASE_URL}/api/piece-rates/${selectedJob}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jobData),
        });
      }
  
      if (response.ok) {
        setSelectedJob(jobName.replace(/\s+/g, '_'));
        setIsNewJob(false);
        await fetchJobs();
        return true;
      } else {
        console.error('Failed to save job data');
        return false;
      }
    } catch (error) {
      console.error('Error saving job data:', error);
      return false;
    }
  };

  return {
    jobs,
    selectedJob,
    jobName,
    handleJobChange,
    handleJobNameChange,
    handleSave,
    createNewJob,
    isNewJob,
    loading,
  };
};

export default useJobData;
