import {
  SET_ITEM_DATA,
  SET_CREW_ITEM_ASSIGNMENTS,
  SET_RESPONSIBILITIES,
  SET_LEVEL_DATA,
  SET_ALL_PEOPLE,
  ADD_LEVEL,
  UPDATE_ITEM_NAME,
  UPDATE_ITEM_PRICE,
  UPDATE_COMPLETION,
  REMOVE_ITEM,
  ADD_RESPONSIBILITY,
  REMOVE_RESPONSIBILITY,
  UPDATE_SQ_FOOTAGE,
  UPDATE_LEVEL_NAME,
  SET_CREWS_ASSIGNED_TO_JOB,
  SET_PROJECT_MANAGER_ASSIGNED_TO_JOB,
  REMOVE_CREW_FROM_JOB,
  ADD_CREW_TO_JOB,
  ADD_PERSON,
  UPDATE_PERSON,
  UPDATE_ITEM_QUANTITY,
  SET_CREWS_ASSIGNED_TO_ITEM,
  UPDATE_CREW_SQ_FOOTAGE,
  UPDATE_CREW_TOTAL_PAY,
} from './pieceRateActions';
import { generateLevelItems, generateItemData } from './pieceRateSchema';

const initialState = {
  itemData: {},
  crewItemAssignments: {},
  responsibilities: {},
  levelData: {},
  allPeople: [],
  crewsAssignedToJob: [],
  projectManagerAssignedToJob: null,
  itemCrewAssignments: {},
  crewSqFootage: {},
  crewTotalPay: {},
  crewCompletion: {}, // New state for crew-specific completion
};

const pieceRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM_DATA:
      return { ...state, itemData: action.payload };
    case SET_CREW_ITEM_ASSIGNMENTS: {
      if (action.payload.itemId && action.payload.crewId !== undefined) {
        const { itemId, crewId } = action.payload;
        const itemName = state.itemData[itemId]?.name;
        const updatedCrewItemAssignments = Object.keys(state.itemData).reduce(
          (acc, key) => {
            if (state.itemData[key].name === itemName) {
              acc[key] = crewId;
            } else {
              acc[key] = state.crewItemAssignments[key];
            }
            return acc;
          },
          {}
        );
        return {
          ...state,
          crewItemAssignments: updatedCrewItemAssignments,
        };
      } else {
        return {
          ...state,
          crewItemAssignments: action.payload,
        };
      }
    }
    case SET_RESPONSIBILITIES:
      return { ...state, responsibilities: action.payload };
    case SET_LEVEL_DATA:
      return { ...state, levelData: action.payload };
    case SET_ALL_PEOPLE:
      return { ...state, allPeople: action.payload };
    case SET_CREWS_ASSIGNED_TO_JOB:
      return { ...state, crewsAssignedToJob: action.payload };
    case SET_PROJECT_MANAGER_ASSIGNED_TO_JOB:
      return {
        ...state,
        projectManagerAssignedToJob: action.payload,
      };
    case REMOVE_CREW_FROM_JOB:
      return {
        ...state,
        crewsAssignedToJob: state.crewsAssignedToJob.filter((crewId) => crewId !== action.payload),
      };
    case ADD_CREW_TO_JOB:
      return {
        ...state,
        crewsAssignedToJob: [...state.crewsAssignedToJob, action.payload],
      };
    case ADD_PERSON:
      return {
        ...state,
        allPeople: [...state.allPeople, action.payload],
      };
    case UPDATE_PERSON:
      return {
        ...state,
        allPeople: state.allPeople.map((person) =>
          person.id === action.payload.id ? action.payload : person
        ),
      };
    case ADD_LEVEL: {
      const { newLevelName } = action.payload;
      const newLevelItems = generateLevelItems(newLevelName);

      return {
        ...state,
        levelData: {
          ...state.levelData,
          [newLevelName]: {
            sqFootage: 0,
            items: newLevelItems.map((item) => item.id),
          },
        },
        itemData: {
          ...state.itemData,
          ...generateItemData(newLevelItems),
        },
        crewItemAssignments: {
          ...state.crewItemAssignments,
          ...newLevelItems.reduce((acc, newItem) => {
            const existingItemId = Object.keys(state.crewItemAssignments).find(
              (itemId) => state.itemData[itemId].name === newItem.name
            );
            acc[newItem.id] = existingItemId ? state.crewItemAssignments[existingItemId] : '';
            return acc;
          }, {}),
        },
        responsibilities: {
          ...state.responsibilities,
          ...newLevelItems.reduce((acc, newItem) => {
            const existingItemId = Object.keys(state.responsibilities).find(
              (itemId) => state.itemData[itemId].name === newItem.name
            );
            acc[newItem.id] = existingItemId ? state.responsibilities[existingItemId] : [];
            return acc;
          }, {}),
        },
      };
    }
    case UPDATE_ITEM_NAME: {
      const { itemId, newItemName } = action.payload;
      return {
        ...state,
        itemData: {
          ...state.itemData,
          [itemId]: {
            ...state.itemData[itemId],
            name: newItemName,
          },
        },
      };
    }
    case UPDATE_ITEM_PRICE: {
      const { item, price } = action.payload;
      const updatedItemData = Object.entries(state.itemData).reduce((acc, [itemId, itemData]) => {
        if (itemData.name === state.itemData[item].name) {
          acc[itemId] = {
            ...itemData,
            defaultPrice: price,
          };
        } else {
          acc[itemId] = itemData;
        }
        return acc;
      }, {});
      return {
        ...state,
        itemData: updatedItemData,
      };
    }
    case UPDATE_COMPLETION: {
      const { itemId, completion, date, crewId } = action.payload;
      const updatedCrewCompletion = {
        ...state.crewCompletion,
        [itemId]: {
          ...state.crewCompletion[itemId],
          [crewId]: completion,
        },
      };

      const allCrewsCompleted = Object.values(updatedCrewCompletion[itemId] || {}).every(
        (comp) => comp === 100
      );

      return {
        ...state,
        crewCompletion: updatedCrewCompletion,
        itemData: {
          ...state.itemData,
          [itemId]: {
            ...state.itemData[itemId],
            completion: allCrewsCompleted ? 100 : state.itemData[itemId].completion,
            date: allCrewsCompleted ? date : state.itemData[itemId].date,
          },
        },
      };
    }
    case REMOVE_ITEM: {
      const { level, item } = action.payload;
      const { [item]: _, ...restItemData } = state.itemData;
      const { [item]: __, ...restCrewItemAssignments } = state.crewItemAssignments;
      const { [item]: ___, ...restResponsibilities } = state.responsibilities;
      return {
        ...state,
        itemData: restItemData,
        crewItemAssignments: restCrewItemAssignments,
        responsibilities: restResponsibilities,
        levelData: {
          ...state.levelData,
          [level]: {
            ...state.levelData[level],
            items: state.levelData[level].items.filter((itemId) => itemId !== item),
          },
        },
      };
    }
    case ADD_RESPONSIBILITY: {
      const { item, responsibility } = action.payload;
      const addItemName = state.itemData[item]?.name;
      const addItemsWithSameName = Object.keys(state.itemData).filter(
        (key) => state.itemData[key].name === addItemName
      );
      const updatedAddResponsibilities = addItemsWithSameName.reduce(
        (acc, key) => ({
          ...acc,
          [key]: [...(state.responsibilities[key] || []), responsibility],
        }),
        {}
      );
      return {
        ...state,
        responsibilities: {
          ...state.responsibilities,
          ...updatedAddResponsibilities,
        },
      };
    }
    case REMOVE_RESPONSIBILITY: {
      const { item, responsibility } = action.payload;
      const removeItemName = state.itemData[item]?.name;
      const removeItemsWithSameName = Object.keys(state.itemData).filter(
        (key) => state.itemData[key].name === removeItemName
      );
      const updatedRemoveResponsibilities = removeItemsWithSameName.reduce(
        (acc, key) => ({
          ...acc,
          [key]: (state.responsibilities[key] || []).filter((resp) => resp !== responsibility),
        }),
        {}
      );
      return {
        ...state,
        responsibilities: {
          ...state.responsibilities,
          ...updatedRemoveResponsibilities,
        },
      };
    }
    case UPDATE_SQ_FOOTAGE: {
      const { level, sqFootage } = action.payload;
      return {
        ...state,
        levelData: {
          ...state.levelData,
          [level]: {
            ...state.levelData[level],
            sqFootage,
          },
        },
      };
    }
    case UPDATE_LEVEL_NAME: {
      const { oldLevelName, newLevelName } = action.payload;
      const updatedLevelData = { ...state.levelData };
      const levelData = updatedLevelData[oldLevelName];
      delete updatedLevelData[oldLevelName];
      updatedLevelData[newLevelName] = levelData;

      return {
        ...state,
        levelData: updatedLevelData,
      };
    }
    case UPDATE_ITEM_QUANTITY: {
      const { itemId, quantity } = action.payload;
      return {
        ...state,
        itemData: {
          ...state.itemData,
          [itemId]: {
            ...state.itemData[itemId],
            quantity,
          },
        },
      };
    }
    case SET_CREWS_ASSIGNED_TO_ITEM: {
      const { itemId, crewIds } = action.payload;
      return {
        ...state,
        itemCrewAssignments: {
          ...state.itemCrewAssignments,
          [itemId]: crewIds,
        },
      };
    }
    case UPDATE_CREW_SQ_FOOTAGE: {
      const { itemId, crewId, sqFootage } = action.payload;
      return {
        ...state,
        crewSqFootage: {
          ...state.crewSqFootage,
          [itemId]: {
            ...state.crewSqFootage[itemId],
            [crewId]: sqFootage,
          },
        },
      };
    }
    case UPDATE_CREW_TOTAL_PAY: {
      const { itemId, crewId, totalPay } = action.payload;
      return {
        ...state,
        crewTotalPay: {
          ...state.crewTotalPay,
          [itemId]: {
            ...state.crewTotalPay[itemId],
            [crewId]: totalPay,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default pieceRateReducer;
