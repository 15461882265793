// Table.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addLevel } from './../pieceRateActions';
import Level from './Level';
import './Table.css';

const Table = ({ isEditable, selectedJob }) => {
  const dispatch = useDispatch()
  const levelData = useSelector((state) => state.pieceRate.levelData);

  const handleAddLevel = () => {
    dispatch(addLevel())
  };

  return (
    <table className="level-table">
      <tbody>
        {Object.entries(levelData).map(([level, data]) => (
          <Level
            key={level}
            level={level}
            isEditable={isEditable}
            selectedJob={selectedJob}
          />
        ))}
        {isEditable && (
          <tr>
            <td colSpan="2" style={{ textAlign: 'right' }}>
              <button onClick={handleAddLevel} className="add-level-button">
                Add Level
              </button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
