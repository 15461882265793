// AddItemForm.js
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addItem } from '../pieceRateActions';
import './AddItemForm.css';

const AddItemForm = ({ level, onCancel }) => {
  const allPeople = useSelector((state) => state.pieceRate.allPeople || []); // Ensure it is an array
  const crewsAssignedToJob = useSelector((state) => state.pieceRate.crewsAssignedToJob || []); // Ensure it is an array
  const [itemName, setItemName] = useState('');
  const [price, setPrice] = useState('');
  const [crew, setCrew] = useState('');
  const [pricingType, setPricingType] = useState('perSqFoot');
  const [quantity, setQuantity] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const parsedPrice = parseFloat(price);
    const parsedQuantity = pricingType === 'perItem' ? parseInt(quantity) : undefined;
    dispatch(addItem(level, itemName, parsedPrice, crew, pricingType, parsedQuantity));
    onCancel();
  };

  // Filter allPeople to get the crews
  const allCrews = allPeople.filter(person => person.role === 'crew');

  return (
    <tr className="add-item-row">
      <td colSpan={pricingType === 'perItem' ? 9 : 8}>
        <form onSubmit={handleSubmit} className="add-item-form">
          <div className="form-group">
            <label htmlFor="itemName">Item Name:</label>
            <input
              type="text"
              id="itemName"
              name="itemName"
              value={itemName}
              onChange={(event) => setItemName(event.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Pricing Type:</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="pricingType"
                  value="perSqFoot"
                  checked={pricingType === 'perSqFoot'}
                  onChange={(event) => setPricingType(event.target.value)}
                />
                Per Square Foot
              </label>
              <label>
                <input
                  type="radio"
                  name="pricingType"
                  value="perItem"
                  checked={pricingType === 'perItem'}
                  onChange={(event) => setPricingType(event.target.value)}
                />
                Per Item
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="price">
              {pricingType === 'perItem' ? 'Price Per Item:' : 'Price Per Sq Foot:'}
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={(event) => setPrice(event.target.value)}
              step="0.01"
              required
            />
          </div>
          {pricingType === 'perItem' && (
            <div className="form-group">
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
                required
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="crew">Crew:</label>
            <select
              id="crew"
              name="crew"
              value={crew}
              onChange={(event) => setCrew(event.target.value)}
            >
              <option value="">Select Crew</option>
              {crewsAssignedToJob.map((crewId) => {
                const crew = allCrews.find((crew) => crew.id === crewId);
                return (
                  <option key={crewId} value={crewId}>
                    {crew ? crew.name : 'Unknown Crew'}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="button-group">
            <div className="button-container">
              <button type="submit" className="submit-button">Add Item</button>
              <button type="button" onClick={onCancel} className="cancel-button">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </td>
    </tr>
  );
};

export default AddItemForm;
