import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ItemDetails from './ItemDetails';
import AddItemForm from './AddItemForm';
import { updateSqFootage, updateLevelName } from '../pieceRateActions';
import './Level.css';

const Level = ({ level, isEditable, selectedJob }) => {
  const itemData = useSelector((state) => state.pieceRate.itemData);
  const levelData = useSelector((state) => state.pieceRate.levelData);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [levelName, setLevelName] = useState(level);

  const dispatch = useDispatch();

  const handleSqFootageChange = (event) => {
    dispatch(updateSqFootage(level, parseFloat(event.target.value)));
  };

  const handleAddItem = () => {
    setIsAddingItem(true);
  };

  const handleCancelAddItem = () => {
    setIsAddingItem(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLevelNameChange = (event) => {
    setLevelName(event.target.value);
  };

  const handleLevelNameBlur = () => {
    dispatch(updateLevelName(level, levelName));
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  const calculateLevelTotal = () => {
    return formatCurrency((levelData[level].items || [])
      .reduce((total, itemId) => total + calculateItemTotal(itemId), 0)
    );
  };

  const calculateItemTotal = (itemId) => {
    const sqFoot = levelData[level]?.sqFootage || 0;
    const price = itemData[itemId]?.defaultPrice || 0;
    const quantity = itemData[itemId]?.quantity || 0;
    const pricingType = itemData[itemId]?.pricingType;

    if (pricingType === 'perSqFoot') {
      return sqFoot * price;
    } else if (pricingType === 'perItem') {
      return price * quantity;
    }
    return 0;
  };

  return (
    <>
      <tr className="level-row">
        <td>
          <button onClick={toggleExpand} className="expand-button">
            {isExpanded ? '-' : '+'}
          </button>
        </td>
        <td colSpan={7}>
          {isEditable ? (
            <input
              type="text"
              value={levelName}
              onChange={handleLevelNameChange}
              onBlur={handleLevelNameBlur}
            />
          ) : (
            <div className="level-name">{level}</div>
          )}
          <div className="sq-footage">
            <span>Sq Footage: </span>
            {isEditable ? (
              <input
                type="number"
                value={levelData[level]?.sqFootage || ''}
                onChange={handleSqFootageChange}
              />
            ) : (
              <span>{formatNumber(levelData[level]?.sqFootage || 0)}</span>
            )}
          </div>
        </td>
      </tr>
      {isExpanded && (
        <>
          <tr className="level-header-row">
            <th></th>
            <th>Item</th>
            <th>Price</th>
            <th>Total Pay</th>
            <th>Crew</th>
            <th>Completed</th>
            <th>Completion Date</th>
            {isEditable && <th>Actions</th>}
          </tr>
          {(levelData[level].items || []).map((itemId) => (
            <ItemDetails
              key={`${level}-${itemId}`}
              level={level}
              itemId={itemId}
              isEditable={isEditable}
              selectedJob={selectedJob}
            />
          ))}
          <tr className="level-total-row">
            <td colSpan={3}>Totals</td>
            <td>Pay Total: {calculateLevelTotal()}</td>
            <td colSpan={isEditable ? 4 : 3}></td>
          </tr>
          {isEditable && (
            <>
              {isAddingItem ? (
                <AddItemForm level={level} onCancel={handleCancelAddItem} />
              ) : (
                <tr>
                  <td></td>
                  <td colSpan={isEditable ? 7 : 6}>
                    <button onClick={handleAddItem}>Add Item</button>
                  </td>
                </tr>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Level;
