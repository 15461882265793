// pieceRateSchema.js

export const defaultItems = [
  { id: 'pre-snap', name: 'Pre-Snap', pricingType: 'perSqFoot' },
  { id: 'snap-lines', name: 'Snap Lines', pricingType: 'perSqFoot' },
  { id: 'cut-parts', name: 'Cut Parts', pricingType: 'perSqFoot' },
  { id: 'cut-plates', name: 'Cut Plates', pricingType: 'perSqFoot' },
  { id: 'detail-plates', name: 'Detail Plates', pricingType: 'perSqFoot' },
  { id: 'frame-walls', name: 'Frame Walls', pricingType: 'perSqFoot' },
  { id: 'straps', name: 'Straps', pricingType: 'perItem' },
  { id: 'pickup', name: 'Pickup', pricingType: 'perSqFoot' },
  { id: 'holdowns', name: 'Holdowns', pricingType: 'perItem' },
];

export const defaultItemData = defaultItems.reduce(
  (acc, item) => ({
    ...acc,
    [item.id]: {
      name: item.name,
      defaultPrice: '',
      pricingType: item.pricingType,
      quantity: item.pricingType === 'perItem' ? '' : undefined,
    },
  }),
  {}
);
  
  export const defaultCrewAssignments = defaultItems.reduce(
    (acc, item) => ({ ...acc, [item.id]: null }),
    {}
  );
  
  export const defaultResponsibilities = defaultItems.reduce(
    (acc, item) => ({ ...acc, [item.id]: [] }),
    {}
  );
  
  export const generateLevelItems = (levelNumber) => {
    return defaultItems.map((item, index) => ({
      id: `${item.id}-${levelNumber}-${index + 1}`,
      name: item.name,
      pricingType: item.pricingType,
    }));
  };
  
  export const generateItemData = (levelItems) => {
    return levelItems.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: {
          name: item.name,
          defaultPrice: '',
          pricingType: item.pricingType,
          quantity: item.pricingType === 'perItem' ? '' : undefined,
        },
      }),
      {}
    );
  };

  export const generateLevelData = (levelNumber) => {
    const levelItems = generateLevelItems(levelNumber);
    return {
      [`Level ${levelNumber}`]: {
        sqFootage: 0,
        items: levelItems.map((item) => item.id),
      },
    };
  };
  
  export const generateCrewAssignments = (levelItems) => {
    return levelItems.reduce((acc, item) => ({ ...acc, [item.id]: null }), {});
  };
  
  export const generateResponsibilities = (levelItems) => {
    return levelItems.reduce((acc, item) => ({ ...acc, [item.id]: [] }), {});
  };
  
  export const defaultLevelData = generateLevelData(1);
  
  export const pieceRateSchema = {
    itemData: {
      ...defaultItemData,
      ...generateItemData(generateLevelItems(1)),
    },
    crewItemAssignments: {
      ...defaultCrewAssignments,
      ...generateCrewAssignments(generateLevelItems(1)),
    },
    responsibilities: {
      ...defaultResponsibilities,
      ...generateResponsibilities(generateLevelItems(1)),
    },
    levelData: defaultLevelData,
  };