import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import CrewRow from './CrewRow';
import {
  updateItemName,
  updateItemPrice,
  updateCompletion,
  removeItem,
  setCrewsAssignedToItem,
} from '../pieceRateActions';
import './ItemDetails.css';

const ItemDetails = ({ level, itemId, isEditable }) => {
  const itemData = useSelector((state) => state.pieceRate.itemData);
  const itemCrewAssignments = useSelector((state) => state.pieceRate.itemCrewAssignments);
  const crewCompletion = useSelector((state) => state.pieceRate.crewCompletion);
  const levelData = useSelector((state) => state.pieceRate.levelData);
  const allPeople = useSelector((state) => state.pieceRate.allPeople);
  const selectedCrews = useSelector((state) => state.pieceRate.crewsAssignedToJob);

  const assignedCrews = useMemo(() => itemCrewAssignments[itemId] || [], [itemCrewAssignments, itemId]);
  const pricingType = itemData[itemId]?.pricingType;
  const [inputsMain, setInputsMain] = useState({
    price: itemData[itemId]?.defaultPrice?.toString() || '',
    completion: itemData[itemId]?.completion || 0,
    date: itemData[itemId]?.date || ''
  });

  const dispatch = useDispatch();

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const roundToNearestCent = (value) => {
    return Math.round(value * 100) / 100;
  };

  const calculateItemTotal = useCallback(() => {
    const price = itemData[itemId]?.defaultPrice || 0;
    const quantity = itemData[itemId]?.quantity || 0;
    const sqFoot = levelData[level]?.sqFootage || 0;

    let total = 0;
    if (pricingType === 'perSqFoot') {
      total = price * sqFoot;
    } else if (pricingType === 'perItem') {
      total = price * quantity;
    }
    return roundToNearestCent(total);
  }, [itemData, itemId, level, levelData, pricingType]);

  useEffect(() => {
    setInputsMain(inputs => ({
      ...inputs,
      totalPay: calculateItemTotal().toString()
    }));
  }, [itemId, itemData, levelData, level, calculateItemTotal]);

  const handleInputMainChange = (field) => (event) => {
    const value = event.target.value;
    setInputsMain(inputs => ({
      ...inputs,
      [field]: value
    }));
  };

  const handleBlurMain = (field) => () => {
    if (field === 'price') {
      const price = parseFloat(inputsMain.price).toFixed(3); // Round to nearest 0.001
      if (!isNaN(price)) {
        dispatch(updateItemPrice(level, itemId, parseFloat(price)));
      }
    } else if (field === 'completion') {
      const completion = parseInt(inputsMain.completion || 0);
      let date = '';

      if (completion === 100) {
        date = new Date().toISOString().split('T')[0];
      }

      dispatch(updateCompletion(level, itemId, completion, date, assignedCrews.length === 1 ? assignedCrews[0] : null));
    }
  };

  const handleCrewAssign = (selectedOptions) => {
    const selectedCrews = selectedOptions ? selectedOptions.map(option => option.value) : [];
    dispatch(setCrewsAssignedToItem(itemId, selectedCrews));
  };

  const handleRemoveItem = () => {
    dispatch(removeItem(level, itemId));
  };

  const allCrewsCompleted = (completion) => {
    const crews = Object.keys(completion[itemId] || {});
    return crews.length > 0 && crews.every(crewId => completion[itemId][crewId] === 100);
  };

  const overallCompletion = allCrewsCompleted(crewCompletion) ? '100%' : '';

  const crews = allPeople.filter(person => person.role === 'crew' && selectedCrews.includes(person.id)).map(crew => ({
    value: crew.id,
    label: crew.name
  }));

  const selectedCrewOptions = assignedCrews.map(crewId => {
    const crew = allPeople.find((person) => person.id === crewId);
    return crew ? { value: crew.id, label: crew.name } : null;
  }).filter(option => option !== null);

  const completionOptions = [
    { value: 0, label: '0%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' },
  ];

  const handleCompletionChange = (selectedOption) => {
    const completion = selectedOption.value;
    const date = completion === 100 ? new Date().toISOString().split('T')[0] : '';

    setInputsMain(inputs => ({
      ...inputs,
      completion,
      date,
    }));
    dispatch(updateCompletion(level, itemId, completion, date, assignedCrews.length === 1 ? assignedCrews[0] : null));
  };

  return (
    <>
      <tr className="item-row">
        <td></td>
        <td>
          {isEditable ? (
            <input
              type="text"
              value={itemData[itemId]?.name || ''}
              onChange={(e) => dispatch(updateItemName(itemId, e.target.value))}
            />
          ) : (
            itemData[itemId]?.name || ''
          )}
        </td>
        <td>
          {isEditable ? (
            <>
              <input
                type="text"
                value={inputsMain.price}
                onChange={handleInputMainChange('price')}
                onBlur={handleBlurMain('price')}
                className="item-price-input"
                placeholder={pricingType === 'perSqFoot' ? 'Price per Sq Foot' : 'Price per Item'}
              />
            </>
          ) : (
            `${formatCurrency(itemData[itemId]?.defaultPrice)}${pricingType === 'perItem' ? ` (${itemData[itemId]?.quantity} items)` : ' / Sq Ft'}`
          )}
        </td>
        <td>
          {formatCurrency(calculateItemTotal())}
        </td>
        <td>
          {isEditable ? (
            <CreatableSelect
              isMulti
              value={selectedCrewOptions}
              onChange={handleCrewAssign}
              options={crews}
              className="crew-dropdown"
              placeholder="Assign Crews"
            />
          ) : (
            assignedCrews.map((crewId) => (
              allPeople.find((person) => person.id === crewId)?.name || 'Unknown Crew'
            )).join(', ')
          )}
        </td>
        {assignedCrews.length === 1 ? (
          <>
            <td>
              {isEditable ? (
                <Select
                  value={{ value: inputsMain.completion, label: `${inputsMain.completion}%` }}
                  onChange={handleCompletionChange}
                  options={completionOptions}
                  className="completion-dropdown"
                />
              ) : (
                `${inputsMain.completion || 0}%`
              )}
            </td>
            <td>
              {isEditable ? (
                <input
                  type="date"
                  value={inputsMain.date}
                  onChange={handleInputMainChange('date')}
                  className="item-date-input"
                />
              ) : (
                inputsMain.date || ''
              )}
            </td>
          </>
        ) : (
          <>
            <td>{overallCompletion}</td>
            <td>
              {overallCompletion && itemData[itemId]?.date
                ? itemData[itemId].date
                : ''}
            </td>
          </>
        )}
        {isEditable && (
          <td>
            <button onClick={handleRemoveItem} className="remove-item-button">
              Remove
            </button>
          </td>
        )}
      </tr>
      {assignedCrews.length > 1 && assignedCrews.map(crewId => (
        <CrewRow
          key={crewId}
          crewId={crewId}
          itemId={itemId}
          level={level}
          isEditable={isEditable}
        />
      ))}
    </>
  );
};

export default ItemDetails;
