// NewJobForm.js
import React, { useState } from 'react';

const NewJobForm = ({ onCreateJob, onCancel }) => {
  const [jobName, setJobName] = useState('');

  const handleJobNameChange = (event) => {
    setJobName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onCreateJob(jobName);
  };

  return (
    <div className="new-job-form">
      <h2>Create New Job</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={jobName}
          onChange={handleJobNameChange}
          placeholder="Enter job name"
          required
        />
        <button type="submit">Create</button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default NewJobForm;