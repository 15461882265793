import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './PeopleManagement.css';
import usePeopleActions from './usePeopleActions';

const PeopleManagement = ({ isOpen, onClose }) => {
  const people = useSelector((state) => state.pieceRate.allPeople);
  const [newPerson, setNewPerson] = useState({ name: '', phoneNumber: '', email: '', role: '' });
  const [errors, setErrors] = useState({});
  const [editedPerson, setEditedPerson] = useState(null);
  const { addPersonAsync, removePersonAsync, updatePersonAsync } = usePeopleActions();

  const validate = () => {
    const newErrors = {};
    if (!newPerson.name) newErrors.name = 'Name is required';
    if (!newPerson.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
    if (!newPerson.role) newErrors.role = 'Role is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddPerson = () => {
    if (validate()) {
      addPersonAsync(newPerson);
      setNewPerson({ name: '', phoneNumber: '', email: '', role: '' });
      setErrors({});
    }
  };

  const handleRemovePerson = (personId) => {
    removePersonAsync(personId);
  };

  const handleUpdatePerson = () => {
    updatePersonAsync(editedPerson);
    setEditedPerson(null);
  };

  const handlePhoneNumberChange = (e) => {
    let phoneNumber = e.target.value.replace(/\D/g, '');
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.slice(0, 10);
    }
    if (phoneNumber.length >= 7) {
      phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length >= 4) {
      phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else if (phoneNumber.length > 0) {
      phoneNumber = `(${phoneNumber}`;
    }
    setNewPerson({ ...newPerson, phoneNumber });
  };

  const handleCloseSidebar = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={`people-management-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="people-management-header">
        <span className="exit-icon" onClick={handleCloseSidebar}>
          &times;
        </span>
        <h2>People Management</h2>
      </div>
      <div className="people-management-content">
        <ul>
          <h3>People</h3>
          {people && people.length > 0 ? (
            people.map((person) => (
              <li key={person.id}>
                {editedPerson?.id === person.id ? (
                  <>
                    <input
                      type="text"
                      value={editedPerson.name}
                      onChange={(e) => setEditedPerson({ ...editedPerson, name: e.target.value })}
                    />
                    <button onClick={handleUpdatePerson}>Save</button>
                  </>
                ) : (
                  <>
                    {person.name} ({person.role})
                    <button onClick={() => setEditedPerson(person)}>Edit</button>
                    <button onClick={() => handleRemovePerson(person.id)}>Remove</button>
                  </>
                )}
              </li>
            ))
          ) : (
            <li>No people available</li>
          )}
        </ul>
        <div>
          <h3>Add Person</h3>
          <input
            type="text"
            placeholder="Name"
            value={newPerson.name}
            onChange={(e) => setNewPerson({ ...newPerson, name: e.target.value })}
          />
          {errors.name && <p className="error">{errors.name}</p>}
          <input
            type="text"
            placeholder="Phone Number"
            value={newPerson.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
          <input
            type="email"
            placeholder="Email"
            value={newPerson.email}
            onChange={(e) => setNewPerson({ ...newPerson, email: e.target.value })}
          />
          <select
            value={newPerson.role}
            onChange={(e) => setNewPerson({ ...newPerson, role: e.target.value })}
          >
            <option value="">Select Role</option>
            <option value="crew">Crew</option>
            <option value="project_manager">Project Manager</option>
          </select>
          {errors.role && <p className="error">{errors.role}</p>}
          <button onClick={handleAddPerson}>Add Person</button>
        </div>
      </div>
    </div>
  );
};

export default PeopleManagement;
