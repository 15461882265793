import API_BASE_URL from '../../../constants/apiConfig';

export const SET_ITEMS = 'SET_ITEMS';
export const UPDATE_ITEM_COMPLETION_START = 'UPDATE_ITEM_COMPLETION_START';
export const UPDATE_ITEM_COMPLETION_SUCCESS = 'UPDATE_ITEM_COMPLETION_SUCCESS';
export const FETCH_FINANCIAL_SUMMARY_SUCCESS = 'FETCH_FINANCIAL_SUMMARY_SUCCESS';

export const setItems = (items, earnings) => ({
  type: SET_ITEMS,
  payload: { items, earnings },
});

export const fetchItemsForCrew = (crewName) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/crew/${crewName}/incompleted-items`);
    if (response.ok) {
      const data = await response.json();
      dispatch(setItems(data.items, data.earnings));
      return data;
    } else {
      console.error('Failed to fetch items for crew');
      return null;
    }
  } catch (error) {
    console.error('Error fetching items for crew:', error);
    return null;
  }
};

export const updateItemCompletion = (itemId, completion, crewName) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ITEM_COMPLETION_START,
      payload: { itemId },
    });

    const response = await fetch(`${API_BASE_URL}/api/item/${itemId}/completion`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ completion }),
    });

    if (response.ok) {
      const data = await dispatch(fetchItemsForCrew(crewName));
      if (data) {
        dispatch({
          type: UPDATE_ITEM_COMPLETION_SUCCESS,
          payload: { itemId, completion, earnings: data.earnings },
        });
      } else {
        dispatch({
          type: UPDATE_ITEM_COMPLETION_SUCCESS,
          payload: { itemId, completion, earnings: 0 },
        });
      }
    } else {
      console.error('Failed to update item completion');
    }
  } catch (error) {
    console.error('Error updating item completion:', error);
  }
};

export const fetchFinancialSummary = (crewName) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/crew/${crewName}/financial-summary`);
    if (response.ok) {
      const data = await response.json();
      dispatch({
        type: FETCH_FINANCIAL_SUMMARY_SUCCESS,
        payload: data,
      });
      return data;
    } else {
      console.error('Failed to fetch financial summary');
      return null;
    }
  } catch (error) {
    console.error('Error fetching financial summary:', error);
    return null;
  }
};

export const updateFinancialSummary = async (crewName, payrollAmount, additionalEarnings) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/crew/${crewName}/financial-summary`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payrollAmount, additionalEarnings }),
    });

    if (!response.ok) {
      console.error('Failed to update financial summary');
    } else {
      console.log('Financial summary updated successfully');
    }
  } catch (error) {
    console.error('Error updating financial summary:', error);
  }
};
