import React, { useState, useEffect } from 'react';
import './CrewFinancialSummary.css';

const CrewFinancialSummary = ({ crewName, startingBalance, endingBalance, earningsThisPeriod, payrollAmount, additionalEarnings, onAdditionalEarningsChange, onPayrollChange, onSave }) => {
  const [payroll, setPayroll] = useState(payrollAmount || '');
  const [additionalEarningsInput, setAdditionalEarningsInput] = useState(additionalEarnings || '');
  const [isSaveVisible, setIsSaveVisible] = useState(false);

  useEffect(() => {
    setAdditionalEarningsInput(additionalEarnings || '');
    setPayroll(payrollAmount || '');
  }, [additionalEarnings, payrollAmount]);

  const handlePayrollChange = (event) => {
    const payrollValue = event.target.value !== '' ? parseFloat(event.target.value) : '';
    setPayroll(payrollValue);
    onPayrollChange(payrollValue);
    setIsSaveVisible(true);
  };

  const handleAdditionalEarningsChange = (event) => {
    const value = event.target.value !== '' ? parseFloat(event.target.value) : '';
    setAdditionalEarningsInput(value);
    onAdditionalEarningsChange(value);
    setIsSaveVisible(true);
  };

  const handleSave = () => {
    const validatedPayroll = payroll !== '' ? payroll : null;
    const validatedAdditionalEarnings = additionalEarningsInput !== '' ? additionalEarningsInput : null;
    onSave(validatedPayroll, validatedAdditionalEarnings);
    setIsSaveVisible(false);
  };

  return (
    <div className="financial-summary">
      <p>
        <span className="label">Starting Balance for This Period:</span>
        <span className="value">{formatCurrency(startingBalance)}</span>
      </p>
      <p>
        <span className="label">Earnings This Period:</span>
        <span className="value">{formatCurrency(earningsThisPeriod)}</span>
      </p>
      <div className="additional-earnings-section">
        <label className="additional-earnings-label">
          Additional Earnings:
          <input
            type="number"
            value={additionalEarningsInput}
            onChange={handleAdditionalEarningsChange}
            placeholder="Enter additional earnings"
            step="0.01"
            className="additional-earnings-input"
          />
        </label>
      </div>
      <div className="payroll-section">
        <label className="payroll-label">
          Payroll for This Period:
          <input
            type="number"
            value={payroll}
            onChange={handlePayrollChange}
            placeholder="Enter payroll amount"
            step="0.01"
            className="payroll-input"
          />
        </label>
      </div>
      {isSaveVisible && (
        <button onClick={handleSave} className="save-button">
          Save
        </button>
      )}
      <p>
        <span className="label">Ending Balance:</span>
        <span className="value">{formatCurrency(endingBalance)}</span>
      </p>
    </div>
  );
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

export default CrewFinancialSummary;
