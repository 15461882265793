// PieceRateTracker.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import JobDropdown from './JobDropdown';
import JobHeader from './JobHeader';
import Table from './PieceRateBody/Table';
import usePieceRateData from './hooks/usePieceRateData';
import useJobData from './hooks/useJobData';
import PeopleAssignmentToJob from './PeopleAssignmentToJob';
import NewJobForm from './NewJobForm';
import usePeopleData from './hooks/usePeopleData';
import './PieceRateTracker.css';

const PieceRateTracker = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [showNewJobForm, setShowNewJobForm] = useState(false);
  const itemData = useSelector((state) => state.pieceRate.itemData);
  const crewItemAssignments = useSelector((state) => state.pieceRate.crewItemAssignments);
  const responsibilities = useSelector((state) => state.pieceRate.responsibilities);
  const levelData = useSelector((state) => state.pieceRate.levelData);
  const peopleOptions = useSelector((state) => state.pieceRate.peopleOptions);
  const projectManagerAssignedToJob = useSelector((state) => state.pieceRate.projectManagerAssignedToJob);
  const [loading, setLoading] = useState(false);

  const {
    jobs,
    selectedJob,
    jobName,
    handleJobChange,
    handleJobNameChange,
    handleSave,
    createNewJob,
    isNewJob,
    loading: jobLoading,
  } = useJobData(setIsEditable);

  usePeopleData();
  
  const {
    toggleEditMode,
  } = usePieceRateData(selectedJob, setIsEditable, isEditable, isNewJob);

  const handleSaveJob = async () => {
    setLoading(true);
    const saveSuccess = await handleSave(itemData, crewItemAssignments, responsibilities, levelData, peopleOptions, projectManagerAssignedToJob);
    if (saveSuccess) {
      setIsEditable(false);
    }
    setLoading(false);
  };

  const handleCreateJob = (newJobName) => {
    if (!newJobName.trim()) {
      return;
    }
  
    createNewJob(newJobName);
    setShowNewJobForm(false);
  };

  const handleCancelNewJob = () => {
    setShowNewJobForm(false);
  };

  return (
    <div className="piece-rate-tracker">
      <h1>Piece Rate Tracking</h1>
      {jobLoading || loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {showNewJobForm ? (
            <NewJobForm onCreateJob={handleCreateJob} onCancel={handleCancelNewJob} />
          ) : (
            <>
              <JobDropdown
                jobs={jobs}
                selectedJob={selectedJob}
                onJobChange={handleJobChange}
                onNewJob={() => setShowNewJobForm(true)}
              />
              {selectedJob && (
                <>
                  <JobHeader
                    jobName={jobName}
                    isEditable={isEditable}
                    onJobNameChange={handleJobNameChange}
                    onSave={handleSaveJob}
                    onEditMode={toggleEditMode}
                    selectedJob={selectedJob}
                    isNewJob={isNewJob}
                  />
                  <PeopleAssignmentToJob
                    selectedJob={selectedJob}
                    isEditable={isEditable}
                  />
                  <Table 
                    isEditable={isEditable}
                    selectedJob={selectedJob}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PieceRateTracker;
