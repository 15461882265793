import {
  SET_ITEMS,
  UPDATE_ITEM_COMPLETION_START,
  UPDATE_ITEM_COMPLETION_SUCCESS,
  FETCH_FINANCIAL_SUMMARY_SUCCESS,
} from './crewViewActions';

const initialState = {
  items: [],
  updatingItemId: null,
  beginningBalance: 0,
  additionalEarnings: 0,
  earnings: 0,
};

const crewViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload.items,
        earnings: action.payload.earnings,
      };
    case UPDATE_ITEM_COMPLETION_START:
      return {
        ...state,
        updatingItemId: action.payload.itemId,
      };
    case UPDATE_ITEM_COMPLETION_SUCCESS:
      return {
        ...state,
        items: state.items.map((job) => ({
          ...job,
          levels: job.levels.map((level) => ({
            ...level,
            items: level.items.map((item) =>
              item.id === action.payload.itemId
                ? { ...item, completion: action.payload.completion }
                : item
            ),
          })),
        })),
        earnings: action.payload.earnings,
        updatingItemId: null,
      };
    case FETCH_FINANCIAL_SUMMARY_SUCCESS:
      return {
        ...state,
        beginningBalance: action.payload.balance,
        additionalEarnings: action.payload.additionalEarnings,
      };
    default:
      return state;
  }
};

export default crewViewReducer;
