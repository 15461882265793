// JobHeader.js
import React from 'react';
import usePieceRateData from './hooks/usePieceRateData';
import './JobHeader.css';

const JobHeader = ({ jobName, isEditable, onJobNameChange, onSave, onEditMode, selectedJob, isNewJob }) => {
  const { handleCancel } = usePieceRateData(selectedJob, onEditMode, isEditable, isNewJob);

  const handleSaveClick = () => {
    onSave();
  };

  return (
    <div className="job-details">
      <div className="job-name">
        {isEditable ? (
          <input
            type="text"
            value={jobName}
            onChange={onJobNameChange}
            placeholder="Enter job name"
          />
        ) : (
          <h2>{jobName}</h2>
        )}
      </div>
      <div className="job-actions">
        {isEditable ? (
          <>
            <button className="save-button" onClick={handleSaveClick}>
              Save
            </button>
            <button className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
          </>
        ) : (
          <button className="edit-button" onClick={onEditMode}>
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default JobHeader;