import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { addCrewToJob, removeCrewFromJob, setProjectManagerAssignedToJob } from './pieceRateActions';
import PeopleManagement from './PeopleSidebar/PeopleManagement';
import usePieceRateData from './hooks/usePieceRateData';
import './PeopleAssignmentToJob.css';

const PeopleAssignmentToJob = ({ selectedJob, isEditable }) => {
  const allPeople = useSelector((state) => state.pieceRate.allPeople);
  const assignedCrews = useSelector((state) => state.pieceRate.crewsAssignedToJob);
  const projectManager = useSelector((state) => state.pieceRate.projectManagerAssignedToJob);
  const dispatch = useDispatch();
  const [isPeopleManagementOpen, setIsPeopleManagementOpen] = useState(false);
  const [selectedCrews, setSelectedCrews] = useState(assignedCrews);

  const { fetchJobData } = usePieceRateData();

  useEffect(() => {
    if (selectedJob && !isEditable) {
      const fetchJobDataEffect = async () => {
        const jobData = await fetchJobData(selectedJob);
        if (jobData && jobData.projectManagerAssignedToJob !== undefined) {
          dispatch(setProjectManagerAssignedToJob(jobData.projectManagerAssignedToJob));
        }
      };
      fetchJobDataEffect();
    }
  }, [selectedJob, isEditable, dispatch, fetchJobData]);

  useEffect(() => {
    setSelectedCrews(assignedCrews);
  }, [assignedCrews]);

  const handleCrewToggle = (crewId) => {
    if (selectedCrews.includes(crewId)) {
      dispatch(removeCrewFromJob(crewId));
      setSelectedCrews(selectedCrews.filter(id => id !== crewId));
    } else {
      dispatch(addCrewToJob(crewId));
      setSelectedCrews([...selectedCrews, crewId]);
    }
  };

  const handleProjectManagerChange = (event) => {
    const projectManagerId = event.target.value;
    dispatch(setProjectManagerAssignedToJob(projectManagerId));
  };

  const handleManagePeopleClick = () => {
    setIsPeopleManagementOpen(true);
  };

  const handlePeopleManagementClose = () => {
    setIsPeopleManagementOpen(false);
  };

  const crews = allPeople.filter((person) => person.role === 'crew');
  const projectManagers = allPeople.filter((person) => person.role === 'project_manager');

  const projectManagerName = projectManager
    ? projectManagers.find((pm) => pm.id === parseInt(projectManager))?.name
    : null;

  return (
    <div className="people-assignment">
      {isEditable && (
        <button className="manage-people-button" onClick={handleManagePeopleClick}>
          Manage People
        </button>
      )}
      {isEditable ? (
        <>
          <div className="project-manager-assignment">
            <h4>Project Manager</h4>
            <select value={projectManager || ''} onChange={handleProjectManagerChange}>
              <option value="">Select Project Manager</option>
              {projectManagers.map((pm) => (
                <option key={pm.id} value={pm.id}>
                  {pm.name}
                </option>
              ))}
            </select>
          </div>
          <div className="crew-list">
            <h4>Crews</h4>
            {crews.map((crew) => (
              <div key={crew.id}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedCrews.includes(crew.id)}
                    onChange={() => handleCrewToggle(crew.id)}
                  />
                  {crew.name}
                </label>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <h4>Project Manager: {projectManagerName || 'Not Assigned'}</h4>
          <h4>Crews</h4>
          <ul className="assigned-people-list">
            {assignedCrews.map((crewId) => {
              const crew = crews.find((person) => person.id === crewId);
              return (
                <li key={crewId}>
                  <Link to={`/piece-rate-tracker/${crew ? crew.name : 'Unknown Crew'}`}>
                    {crew ? `${crew.name}` : 'Unknown Crew'}
                  </Link>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <PeopleManagement isOpen={isPeopleManagementOpen} onClose={handlePeopleManagementClose} />
    </div>
  );
};

export default PeopleAssignmentToJob;
