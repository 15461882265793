import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  updateCompletion,
  updateCrewSqFootage,
} from '../pieceRateActions';
import './CrewRow.css';

const CrewRow = ({ crewId, itemId, level, isEditable }) => {
  const itemData = useSelector((state) => state.pieceRate.itemData);
  const crewSqFootage = useSelector((state) => state.pieceRate.crewSqFootage);
  const allPeople = useSelector((state) => state.pieceRate.allPeople);
  const crewCompletion = useSelector((state) => state.pieceRate.crewCompletion);

  const [crewInputs, setCrewInputs] = useState({
    sqFootage: crewSqFootage[itemId]?.[crewId]?.toString() || '',
    totalPay: '',
    completion: crewCompletion[itemId]?.[crewId] || 0,
    date: crewCompletion[itemId]?.[crewId] === 100 ? itemData[itemId]?.date || '' : '',
  });

  const dispatch = useDispatch();

  const roundToNearestCent = (value) => {
    return Math.round(value * 100) / 100;
  };

  const calculateCrewItemTotal = useCallback(() => {
    const pricePerSqFoot = itemData[itemId]?.defaultPrice || 0;
    const sqFootage = parseFloat(crewInputs.sqFootage) || 0;

    let total = pricePerSqFoot * sqFootage;
    return roundToNearestCent(total);
  }, [crewInputs.sqFootage, itemData, itemId]);

  useEffect(() => {
    setCrewInputs(inputs => ({
      ...inputs,
      totalPay: calculateCrewItemTotal().toString(),
      date: crewCompletion[itemId]?.[crewId] === 100 ? itemData[itemId]?.date || '' : '',
    }));
  }, [itemId, itemData, calculateCrewItemTotal, crewCompletion, crewId]);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setCrewInputs(inputs => ({
      ...inputs,
      [field]: value,
      totalPay: field === 'sqFootage' ? calculateCrewItemTotal().toString() : inputs.totalPay
    }));
  };

  const handleBlur = (field) => () => {
    if (field === 'completion') {
      const completion = parseInt(crewInputs.completion || 0);
      let date = '';

      if (completion === 100) {
        date = new Date().toISOString().split('T')[0];
      }

      setCrewInputs(inputs => ({
        ...inputs,
        date,
      }));
      dispatch(updateCompletion(level, itemId, completion, date, crewId));
    }
    if (field === 'sqFootage') {
      dispatch(updateCrewSqFootage(itemId, crewId, parseFloat(crewInputs.sqFootage)));
    }
  };

  const handleCompletionChange = (selectedOption) => {
    const completion = selectedOption.value;
    const date = completion === 100 ? new Date().toISOString().split('T')[0] : '';

    setCrewInputs(inputs => ({
      ...inputs,
      completion,
      date: completion === 100 ? date : inputs.date,
    }));

    dispatch(updateCompletion(level, itemId, completion, date, crewId));
  };

  const completionOptions = [
    { value: 0, label: '0%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' },
  ];

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  return (
    <>
      <tr key={crewId} className="item-row">
        <td></td>
        <td></td>
        <td>
          {isEditable ? (
            <div className="price-input-container">
              <input
                type="number"
                value={crewInputs.sqFootage || ''}
                onChange={handleInputChange('sqFootage')}
                onBlur={handleBlur('sqFootage')}
                className="sq-footage-input"
                placeholder="Sq Footage"
              />
              <span className="sq-ft-stamp"> Sq Ft</span>
            </div>
          ) : (
            `${crewInputs.sqFootage || ''} Sq Ft`
          )}
        </td>
        <td>
          {formatCurrency(calculateCrewItemTotal())}
        </td>
        <td>
          <div className="crew-row">
            <strong>{allPeople.find((person) => person.id === crewId)?.name || 'Unknown Crew'}</strong>
          </div>
        </td>
        <td>
          {isEditable ? (
            <Select
              value={{ value: crewInputs.completion, label: `${crewInputs.completion}%` }}
              onChange={handleCompletionChange}
              options={completionOptions}
              className="completion-dropdown"
            />
          ) : (
            `${crewInputs.completion || 0}%`
          )}
        </td>
        <td>
          {crewInputs.date}
        </td>
        {isEditable && <td></td>}
      </tr>
    </>
  );
};

export default CrewRow;
