// pieceRateActions.js
export const SET_ITEM_DATA = 'SET_ITEM_DATA';
export const SET_CREW_ITEM_ASSIGNMENTS = 'SET_CREW_ITEM_ASSIGNMENTS';
export const SET_RESPONSIBILITIES = 'SET_RESPONSIBILITIES';
export const SET_LEVEL_DATA = 'SET_LEVEL_DATA';
export const SET_ALL_PEOPLE = 'SET_ALL_PEOPLE';
export const ADD_LEVEL = 'ADD_LEVEL';
export const UPDATE_ITEM_NAME = 'UPDATE_ITEM_NAME';
export const UPDATE_ITEM_PRICE = 'UPDATE_ITEM_PRICE';
export const UPDATE_COMPLETION = 'UPDATE_COMPLETION';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const ADD_RESPONSIBILITY = 'ADD_RESPONSIBILITY';
export const REMOVE_RESPONSIBILITY = 'REMOVE_RESPONSIBILITY';
export const UPDATE_SQ_FOOTAGE = 'UPDATE_SQ_FOOTAGE';
export const UPDATE_LEVEL_NAME = 'UPDATE_LEVEL_NAME';
export const SET_CREWS_ASSIGNED_TO_JOB = 'SET_CREWS_ASSIGNED_TO_JOB';
export const REMOVE_CREW_FROM_JOB = 'REMOVE_CREW_FROM_JOB';
export const ADD_CREW_TO_JOB = 'ADD_CREW_TO_JOB';
export const ADD_PERSON = 'ADD_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';
export const SET_CREWS_ASSIGNED_TO_ITEM = 'SET_CREWS_ASSIGNED_TO_ITEM';
export const UPDATE_CREW_SQ_FOOTAGE = 'UPDATE_CREW_SQ_FOOTAGE';
export const UPDATE_CREW_TOTAL_PAY = 'UPDATE_CREW_TOTAL_PAY';
export const UPDATE_CREW_COMPLETION = 'UPDATE_CREW_COMPLETION';
export const SET_PROJECT_MANAGER_ASSIGNED_TO_JOB = 'SET_PROJECT_MANAGER_ASSIGNED_TO_JOB'; // Add this line

export const setItemData = (itemData) => ({
  type: SET_ITEM_DATA,
  payload: itemData,
});

export const setCrewItemAssignments = (crewItemAssignments) => ({
  type: SET_CREW_ITEM_ASSIGNMENTS,
  payload: crewItemAssignments,
});

export const setProjectManagerAssignedToJob = (projectManagerId) => ({
  type: SET_PROJECT_MANAGER_ASSIGNED_TO_JOB,
  payload: projectManagerId,
});

export const setResponsibilities = (responsibilities) => ({
  type: SET_RESPONSIBILITIES,
  payload: responsibilities,
});

export const setLevelData = (levelData) => ({
  type: SET_LEVEL_DATA,
  payload: levelData,
});

export const setAllPeople = (people) => ({
  type: SET_ALL_PEOPLE,
  payload: people,
});

export const addLevel = () => (dispatch, getState) => {
  const state = getState();
  const existingLevels = state.pieceRate.levelData;
  let newLevelNumber = Object.keys(existingLevels).length + 1;
  let newLevelName = `Level ${newLevelNumber}`;

  while (existingLevels.hasOwnProperty(newLevelName)) {
    newLevelNumber++;
    newLevelName = `Level ${newLevelNumber}`;
  }

  dispatch({
    type: ADD_LEVEL,
    payload: { newLevelName },
  });
};

export const updateItemName = (itemId, newItemName) => ({
  type: UPDATE_ITEM_NAME,
  payload: { itemId, newItemName },
});

export const updateItemPrice = (level, item, price) => ({
  type: UPDATE_ITEM_PRICE,
  payload: { level, item, price },
});

export const updateCompletion = (level, itemId, completion, date, crewId = null) => ({
  type: UPDATE_COMPLETION,
  payload: { level, itemId, completion, date, crewId },
});


export const addItem = (level, itemName, price, crew, pricingType, quantity) => ({
  type: ADD_ITEM,
  payload: { level, itemName, price, crew, pricingType, quantity },
});

export const removeItem = (level, item) => ({
  type: REMOVE_ITEM,
  payload: { level, item },
});

export const addResponsibility = (item, responsibility) => ({
  type: ADD_RESPONSIBILITY,
  payload: { item, responsibility },
});

export const removeResponsibility = (item, responsibility) => ({
  type: REMOVE_RESPONSIBILITY,
  payload: { item, responsibility },
});

export const updateSqFootage = (level, sqFootage) => ({
  type: UPDATE_SQ_FOOTAGE,
  payload: { level, sqFootage },
});

export const updateLevelName = (oldLevelName, newLevelName) => ({
  type: UPDATE_LEVEL_NAME,
  payload: { oldLevelName, newLevelName },
});

export const setCrewsAssignedToJob = (crewsAssignedToJob) => ({
  type: SET_CREWS_ASSIGNED_TO_JOB,
  payload: crewsAssignedToJob,
});

export const removeCrewFromJob = (crewId) => ({
  type: REMOVE_CREW_FROM_JOB,
  payload: crewId,
});

export const addCrewToJob = (crewId) => ({
  type: ADD_CREW_TO_JOB,
  payload: crewId,
});

export const addPerson = (person) => ({
  type: ADD_PERSON,
  payload: person,
});

export const updatePerson = (person) => ({ 
  type: UPDATE_PERSON,
  payload: person,
});

export const updateItemQuantity = ( itemId, quantity) => ({
  type: UPDATE_ITEM_QUANTITY,
  payload: { itemId, quantity },
});

export const setCrewsAssignedToItem = (itemId, crewIds) => ({
  type: SET_CREWS_ASSIGNED_TO_ITEM,
  payload: { itemId, crewIds },
});

export const updateCrewSqFootage = (itemId, crewId, sqFootage) => ({
  type: UPDATE_CREW_SQ_FOOTAGE,
  payload: { itemId, crewId, sqFootage },
});

export const updateCrewTotalPay = (itemId, crewId, totalPay) => ({
  type: UPDATE_CREW_TOTAL_PAY,
  payload: { itemId, crewId, totalPay },
});

export const updateCrewCompletion = (itemId, crewId, completion) => ({
  type: UPDATE_CREW_COMPLETION,
  payload: { itemId, crewId, completion },
});
