//  src/modules/LandingPage/LandingPage.js

import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  // Function to scroll smoothly to the About section
  const scrollToAbout = (event) => {
    event.preventDefault();
    const aboutSection = document.getElementById('about');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="landing-page">
      <section id="landing" className="banner-section">
        <h1>Welcome to Bridgepoint AI</h1>
        <p>Creating AI Tools for the Construction Industry.</p>
        <div>
          <a href="#about" className="btn btn-outline-secondary" onClick={scrollToAbout}>
            Learn More
          </a>
        </div>
      </section>
      <section id="about" className="about-section">
        <h2>About Bridgepoint AI</h2>
        <p>
          Creating innovative construction management tools. To view our Change Order Assistant, follow{' '}
          <a href="https://www.changeorderassistant.com/" target="_blank" rel="noopener noreferrer">
            this link
          </a>
        </p>
      </section>
    </div>
  );
};

export default LandingPage;
