import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './Modules/LandingPage/LandingPage';
import PieceRateTracker from './Modules/PieceRateTracker/PieceRateTracker';
import CrewDashboard from './Modules/PieceRateTracker/CrewView/CrewDashboard';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/piece-rate-tracker" element={<PieceRateTracker />} />
        <Route path="/piece-rate-tracker/:crewName" element={<CrewDashboard />} />
      </Routes>
    </Router>
  );
};

export default App;