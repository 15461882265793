import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import API_BASE_URL from '../../../constants/apiConfig';
import { setAllPeople } from '../pieceRateActions';

const usePeopleData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPeopleData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/people`);
        if (response.ok) {
          const data = await response.json();
          dispatch(setAllPeople(data.people));
        } else {
          console.error('Failed to fetch people data');
        }
      } catch (error) {
        console.error('Error fetching people data:', error);
      }
    };

    fetchPeopleData();
  }, [dispatch]);
};

export default usePeopleData;
