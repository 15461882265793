import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import API_BASE_URL from '../../../constants/apiConfig';
import {
  setItemData,
  setCrewItemAssignments,
  setResponsibilities,
  setLevelData,
  setCrewsAssignedToJob,
  setProjectManagerAssignedToJob,
} from '../pieceRateActions';
import { pieceRateSchema } from '../pieceRateSchema';

const usePieceRateData = (selectedJob, setIsEditable, isEditable, isNewJob) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState(null);

  const resetJobData = useCallback(() => {
    dispatch(setItemData(pieceRateSchema.itemData));
    dispatch(setCrewItemAssignments(pieceRateSchema.crewItemAssignments));
    dispatch(setResponsibilities(pieceRateSchema.responsibilities));
    dispatch(setLevelData(pieceRateSchema.levelData));
    dispatch(setCrewsAssignedToJob([]));
    dispatch(setProjectManagerAssignedToJob(null));
    setLoading(false);
  }, [dispatch]);

  const fetchJobData = useCallback(async (job) => {
    try {
      setLoading(true);
      const formattedJobName = job.replace(/_/g, ' ');
      const response = await fetch(`${API_BASE_URL}/api/piece-rates/${encodeURIComponent(formattedJobName)}`);
      if (response.ok) {
        const data = await response.json();
        if (data.current_big_dict) {
          const jobData = JSON.parse(data.current_big_dict);
          dispatch(setItemData(jobData.itemData || {}));
          dispatch(setCrewItemAssignments(jobData.crewItemAssignments || {}));
          dispatch(setResponsibilities(jobData.responsibilities || {}));
          dispatch(setLevelData(jobData.levelData || {}));
          dispatch(setCrewsAssignedToJob(jobData.crewsAssignedToJob || []));
          dispatch(setProjectManagerAssignedToJob(jobData.projectManagerAssignedToJob || null));
          setOriginalData({
            itemData: jobData.itemData || {},
            crewItemAssignments: jobData.crewItemAssignments || {},
            responsibilities: jobData.responsibilities || {},
            levelData: jobData.levelData || {},
            crewsAssignedToJob: jobData.crewsAssignedToJob || [],
            projectManagerAssignedToJob: jobData.projectManagerAssignedToJob || null,
          });
        } else {
          resetJobData();
        }
      } else {
        console.error(`Failed to fetch data for job ${job}`);
      }
    } catch (error) {
      console.error(`Error fetching data for job ${job}:`, error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, resetJobData]);

  useEffect(() => {
    if (selectedJob) {
      if (isNewJob) {
        resetJobData();
      } else {
        fetchJobData(selectedJob);
      }
    }
  }, [selectedJob, isNewJob, fetchJobData, resetJobData]);

  const toggleEditMode = () => {
    setIsEditable((prevMode) => !prevMode);
  };

  const handleCancel = () => {
    setIsEditable(false);
    if (originalData) {
      dispatch(setItemData(originalData.itemData));
      dispatch(setCrewItemAssignments(originalData.crewItemAssignments));
      dispatch(setResponsibilities(originalData.responsibilities));
      dispatch(setLevelData(originalData.levelData));
      dispatch(setCrewsAssignedToJob(originalData.crewsAssignedToJob));
      dispatch(setProjectManagerAssignedToJob(originalData.projectManagerAssignedToJob));
    }
  };

  return {
    toggleEditMode,
    handleCancel,
    loading,
    fetchJobData,
  };
};

export default usePieceRateData;
