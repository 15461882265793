// src/redux/index.js
import { combineReducers } from 'redux';
import pieceRateReducer from './../Modules/PieceRateTracker/pieceRateReducer';
import crewViewReducer from './../Modules/PieceRateTracker/CrewView/crewViewReducer';

const rootReducer = combineReducers({
  pieceRate: pieceRateReducer,
  crewView: crewViewReducer,
});

export default rootReducer;